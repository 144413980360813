<div class="app-installation" *ngIf="booking">
  <h2>Installation :</h2>
  Booking : <br />
  <table>
    <tbody>
      <tr>
        <td class="title">Booking</td>
        <td>{{ booking.code }}</td>
      </tr>
    </tbody>
  </table>
  <br />
  <div class="cf_item" *ngFor="let cf_item of items_for_installation">
    eSIM-Type : #{{ cf_item.item_index }} : {{ cf_item.item.package_title }}<br />
    <div class="esim_item" *ngFor="let esim of cf_item.esims">
      <table *ngIf="esim.esim" class="esim_item">
        <tbody>
          <tr>
            <td class="title">NUMBER</td>
            <td>{{ esim.esim.telno }}</td>
          </tr>
          <tr>
            <td class="title">ICCID</td>
            <td>{{ esim.esim.iccid }}</td>
          </tr>
          <tr *ngIf="esim.esim.installed_at">
            <td class="title">High-speed data</td>
            <td *ngIf="esim.esim.volume >= 1000">
              <ng-container *ngIf="cf_item.item.unlimited === true">
                {{ esim.esim.volume | decimalTo : 0 : "M" : "G" }}GB/day ({{ esim.esim.period }}days)
              </ng-container>
              <ng-container *ngIf="cf_item.item.unlimited === false">
                {{ esim.esim.volume | decimalTo : 0 : "M" : "G" }} GB / {{ esim.esim.period }} days
              </ng-container>
            </td>
            <td *ngIf="esim.esim.volume < 1000">
              <ng-container *ngIf="cf_item.item.unlimited === true">
                {{ esim.esim.volume }}MB/day ({{ esim.esim.period }}days)
              </ng-container>
              <ng-container *ngIf="cf_item.item.unlimited === false">
                {{ esim.esim.volume }} MB / {{ esim.esim.period }} days
              </ng-container>
            </td>
          </tr>
          <tr *ngIf="!esim.esim.installed_at">
            <td class="title">eSIM issued</td>
            <td>{{ esim.esim.start_using_at | date : "y-MM-dd HH:mm" }}</td>
          </tr>
          <tr *ngIf="esim.esim.installed_at">
            <td class="title">Installed</td>
            <td>{{ esim.esim.installed_at | date : "y-MM-dd HH:mm" }}</td>
          </tr>
          <tr *ngIf="esim.esim.installed_at">
            <td class="title">End</td>
            <td>{{ esim.esim.end_at | date : "y-MM-dd HH:mm" }}</td>
          </tr>
          <tr *ngIf="!esim.esim.installed_at">
            <td class="title">SM-DP address</td>
            <td>{{ smDpAddress(esim.esim.activation_code) }}</td>
          </tr>
          <tr>
            <td class="title">Activation Code</td>
            <td>{{ acctivationCode(esim.esim.activation_code) }}</td>
          </tr>
          <tr>
            <td colspan="2">
              <button id="activation-qr-code-button" mat-flat-button color="primary" (click)="onShowQRCode(esim.esim.telno, esim.esim.activation_code, esim.esim.access_hash)">
                Show QR-CODE
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="!esim.esim">
        <button mat-flat-button color="primary" (click)="onStartUsingESim(cf_item.item.hash)">Start using eSIM</button>
      </div>
    </div>
  </div>
</div>
