<div class="app-booking" *ngIf="booking">
  <table class="head">
    <tbody>
      <tr>
        <td class="title">Booking : <wbr />{{ booking.code }}</td>
        <td class="contact_us"><a mat-button color="basic" href="{{contactUsUrl}}" target="_blank">CONTACT US</a></td>
      </tr>
    </tbody>
  </table>
  <app-maintenance-info></app-maintenance-info>
  Booking : <br />
  <table>
    <tbody>
      <tr>
        <td class="title">Booking</td>
        <td>{{ booking.code }}</td>
      </tr>
      <tr>
        <td class="title">Created</td>
        <td>{{ booking.order_created_at | date : "y-MM-dd HH:mm" }}</td>
      </tr>
    </tbody>
  </table>

  eSIM :
  <table *ngFor="let item of booking.order.items">
    <tr>
      <td class="title">Plan</td>
      <td>{{ item.package_title }}</td>
    </tr>
    <tr>
      <td class="title">Qty</td>
      <td>{{ item.qty }}</td>
    </tr>
    <tr>
      <td colspan="2">
        <table *ngFor="let esim of item.esims" class="esim_item">
          <tbody>
            <tr>
              <td class="title">Line number</td>
              <td colspan="2">{{ esim.telno }}&nbsp;({{ esim.status }})</td>
            </tr>
            <tr>
              <td class="title">ICCID</td>
              <td colspan="2">{{ esim.iccid }}</td>
            </tr>
            <tr *ngIf="esim.installed_at">
              <td class="title">High-speed data</td>
              <td *ngIf="esim.period === null">
                <ng-container *ngIf="esim.volume >= 1000">
                  <ng-container *ngIf="item.unlimited === true">
                    {{ esim.volume | decimalTo : 0 : "M" : "G" }}GB/day ({{ item.period }}days)
                  </ng-container>
                  <ng-container *ngIf="item.unlimited === false">
                    {{ esim.volume | decimalTo : 0 : "M" : "G" }} GB / {{ item.period }} days
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="esim.volume < 1000">
                  <ng-container *ngIf="item.unlimited === true">
                    {{ esim.volume }}MB/day ({{ item.period }}days)
                  </ng-container>
                  <ng-container *ngIf="item.unlimited === false">
                    {{ esim.volume }} MB / {{ item.period }} days
                  </ng-container>
                </ng-container>
              </td>
              <td *ngIf="esim.period !== null">
                <ng-container *ngIf="esim.volume >= 1000">
                  <ng-container *ngIf="item.unlimited === true">
                    {{ esim.volume | decimalTo : 0 : "M" : "G" }}GB/day ({{ esim.period }}days)
                  </ng-container>
                  <ng-container *ngIf="item.unlimited === false">
                    {{ esim.volume | decimalTo : 0 : "M" : "G" }} GB / {{ esim.period }} days
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="esim.volume < 1000">
                  <ng-container *ngIf="item.unlimited === true">
                    {{ esim.volume }}MB/day ({{ esim.period }}days)
                  </ng-container>
                  <ng-container *ngIf="item.unlimited === false">
                    {{ esim.volume }} MB / {{ esim.period }} days
                  </ng-container>
                </ng-container>
              </td>
            </tr>
            <tr *ngIf="!esim.installed_at">
              <td class="title">eSIM issued</td>
              <td colspan="2">{{ esim.start_using_at | date : "y-MM-dd HH:mm" }}</td>
            </tr>
            <tr *ngIf="esim.installed_at">
              <td class="title">Installed</td>
              <td>{{ esim.installed_at | date : "y-MM-dd HH:mm" }}</td>
            </tr>
            <tr *ngIf="esim.installed_at">
              <td class="title">End</td>
              <td>{{ esim.end_at | date : "y-MM-dd HH:mm" }}</td>
            </tr>
            <tr *ngIf="esim.installed_at && isToolAvailable(esim.status)">
              <td class="title">Tools</td>
              <td>
                <a *ngIf="!in_maintenance.in_schedule" class="link myesim" [routerLink]="['/topup']" [queryParams]="{ hash: esim.hash }"
                  >{{ extensionLabel(item) }}</a
                >
                <a *ngIf="in_maintenance.in_schedule" class="link myesim disabled">{{ extensionLabel(item) }}</a>
                <a *ngIf="!in_maintenance.in_schedule" class="link myesim" [routerLink]="['/usage']" [queryParams]="{ hash: esim.hash }">Usage</a>
                <a *ngIf="in_maintenance.in_schedule" class="link myesim disabled">Usage</a>
              </td>
            </tr>
            <tr *ngIf="!esim.installed_at && isToolAvailable(esim.status)">
              <td class="title">SM-DP address (iOS)</td>
              <td class="value">
                {{ smDpAddress(esim.activation_code) }}
              </td>
              <td class="copy">
                <button mat-icon-button (click)="onCopySmDpAddress(smDpAddress(esim.activation_code))"><mat-icon> content_copy </mat-icon></button>
              </td>
            </tr>
            <tr *ngIf="!esim.installed_at && isToolAvailable(esim.status)">
              <td class="title">Activation code (iOS)</td>
              <td class="value">
                {{ activationCode(esim.activation_code) }}
              </td>
              <td class="copy">
                <button mat-icon-button (click)="onCopyActivationCode(activationCode(esim.activation_code))"><mat-icon> content_copy </mat-icon></button>
              </td>
            </tr>
            <tr *ngIf="!esim.installed_at && isToolAvailable(esim.status)">
              <td class="title">Activation code (Android)</td>
              <td class="value">
                {{ esim.activation_code }}
              </td>
              <td class="copy">
                <button mat-icon-button (click)="onCopyFullActivationCode(esim.activation_code)"><mat-icon> content_copy </mat-icon></button>
              </td>
            </tr>
            <tr *ngIf="!esim.installed_at && isToolAvailable(esim.status)">
              <td colspan="3">
                <div class="button-container">
                  <button id="activation-qr-code-button" mat-flat-button color="primary" (click)="onShowQRCode(esim)">
                    <span class="btn-pointer">Show Activation QR-Code</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="button-container">
          <ng-container *ngIf="booking.get_qr_button === true; else disable">
            <button *ngFor="let blank_esim of item.blank_esims" mat-flat-button color="primary" (click)="onStartUsingESim(item.hash)">
              <span class="btn-pointer">Get QR-Code</span>
            </button>
          </ng-container>
          <ng-template #disable>
            <button *ngFor="let blank_esim of item.blank_esims" mat-flat-button color="#fff" class="mdc-button btn-disabled" aria-disabled="true">
              <span>Get QR-Code</span>
            </button>
          </ng-template>
          <a href="https://support.sakuramobile.jp/hc/en-us/articles/27357406514585-eSIM-installation-trouble-shooting" target="_blank" mat-flat-button color="primary">
            Trouble Shooting
          </a>
          <p *ngIf="booking.get_qr_button === false && item.blank_esims.length !== 0">
            The QR code can be issued on {{ booking.get_qr_start_at }} (7 days prior to the selected Start Date of your usage period). If your planned Start Date changes, please make sure to <a class="underline" href="{{contactUsUrl}}" target="_blank">CONTACT US</a>.
          </p>
        </div>
      </td>
    </tr>
  </table>
</div>
