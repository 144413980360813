import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bytesTo'
})
export class BytesToPipe implements PipeTransform {

  // transform(value: number, ...args: <unknown>[]): string {
  transform(value: number,
    dicimal_digits: number,
    from_unit: 'B' | 'KB' | 'MB' | 'GB' | string,
    to_unit: 'B' | 'KB' | 'MB' | 'GB' | string,
    round: 'up' | 'off' | 'down' | string = 'off'): string {

    const IIJ_K_UNIT = 1024;
    const IIJ_K_GB_UNIT = 1000;
    let current_value = value;
    const up_from_unit = from_unit.toUpperCase();
    const up_to_unit = to_unit.toUpperCase();
    if (up_from_unit === 'B') {
      switch (to_unit.toUpperCase()) {
        case 'B':
          break;
        case 'KB':
          current_value = (current_value / IIJ_K_UNIT);
          break;
        case 'MB':
          current_value = (current_value / (IIJ_K_UNIT * IIJ_K_UNIT));
          break;
        case 'GB':
          current_value = (current_value / (IIJ_K_UNIT * IIJ_K_UNIT * IIJ_K_GB_UNIT));
          break;
        default:
          break;
      }
    }
    else if (up_from_unit === 'KB') {
      switch (to_unit.toUpperCase()) {
        case 'B':
          current_value = (current_value * IIJ_K_UNIT);
          break;
        case 'KB':
          break;
        case 'MB':
          current_value = (current_value / (IIJ_K_UNIT));
          break;
        case 'GB':
          current_value = (current_value / (IIJ_K_UNIT * IIJ_K_GB_UNIT));
          break;
        default:
          break;
      }
    }
    else if (up_from_unit === 'MB') {
      switch (to_unit.toUpperCase()) {
        case 'B':
          current_value = (current_value * IIJ_K_UNIT * IIJ_K_UNIT);
          break;
        case 'KB':
          current_value = (current_value * IIJ_K_UNIT);
          break;
        case 'MB':
          break;
        case 'GB':
          current_value = (current_value / (IIJ_K_GB_UNIT));
          break;
        default:
          break;
      }
    }
    else if (up_from_unit === 'GB') {
      switch (to_unit.toUpperCase()) {
        case 'B':
          current_value = (current_value * IIJ_K_UNIT * IIJ_K_UNIT * IIJ_K_GB_UNIT);
          break;
        case 'KB':
          current_value = (current_value * IIJ_K_UNIT * IIJ_K_GB_UNIT);
          break;
        case 'MB':
          current_value = (current_value * IIJ_K_GB_UNIT);
          break;
        case 'GB':
          break;
        default:
          break;
      }
    }
    if (round === 'up') {
      const rounded_value = Math.ceil(current_value * 10**(dicimal_digits)) / 10**(dicimal_digits);
      return rounded_value.toFixed(dicimal_digits);
    }
    else if (round === 'down') {
      const rounded_value = Math.floor(current_value * 10**(dicimal_digits)) / 10**(dicimal_digits);
      return rounded_value.toFixed(dicimal_digits);
    }
    else {
      return current_value.toFixed(dicimal_digits);
    }
  }
}