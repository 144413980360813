import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QRCodeModule } from 'angularx-qrcode';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { SimpleNotificationComponent } from './components/share/simple-notification/simple-notification.component';
import { BookingComponent } from './components/pages/booking/booking.component';
import { TopupComponent } from './components/pages/topup/topup.component';
import { UsageComponent } from './components/pages/usage/usage.component';
import { InstallationComponent } from './components/pages/installation/installation.component';
import { StartUsingConfirmationComponent } from './components/dialogs/start-using-confirmation/start-using-confirmation.component';
import { QrCodeComponent } from './components/dialogs/qr-code/qr-code.component';
import { BytesToPipe } from './pipes/bytes-to.pipe';
import { NextDayToDatePipe } from './pipes/next-day-to-date.pipe';
import { WeekdayPipe } from './pipes/weekday.pipe';
import { CreditCardNumberComponent } from './components/share/credit-card/credit-card-number/credit-card-number.component';
import { CreditCardMmyyComponent } from './components/share/credit-card/credit-card-mmyy/credit-card-mmyy.component';
import { SpinnerComponent } from './components/share/spinner/spinner.component';
import { DecimalToPipe } from './pipes/decimal-to.pipe';
import { ConfirmationComponent } from './components/dialogs/confirmation/confirmation.component';
import { DatePickComponent } from './components/dialogs/date-pick/date-pick.component';
import { MaintenanceInfoComponent } from './components/share/maintenance-info/maintenance-info.component';

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    SimpleNotificationComponent,
    BookingComponent,
    TopupComponent,
    UsageComponent,
    InstallationComponent,
    StartUsingConfirmationComponent,
    QrCodeComponent,
    BytesToPipe,
    NextDayToDatePipe,
    WeekdayPipe,
    CreditCardNumberComponent,
    CreditCardMmyyComponent,
    SpinnerComponent,
    DecimalToPipe,
    ConfirmationComponent,
    DatePickComponent,
    MaintenanceInfoComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatTableModule,
    MatSidenavModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    QRCodeModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatIconModule
  ],
  providers: [
    MatSnackBarModule,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
