import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'my_esim';

  private _system_notification_room_in: boolean;

  constructor(
    public router: Router
  ) {
    this._system_notification_room_in = false;
  }

  ngOnInit(): void {
    const self = this;
  }
}
