<div class="app-qr-code">
  <div class="inner">
    <p>The day of installation will be the Start Date of your usage period. If you start using it now, the usage period will be as follows.</p>
    <p class="bold red">
      Start date: {{ activation_data.estimated_start_at }}<br/>
      End date: {{ activation_data.estimated_end_at }}
    </p>
    <!-- <qrcode [qrdata]="activation_data.qr_code_data" [width]="240" [errorCorrectionLevel]="'H'"></qrcode> -->
    <img src="{{qrUrl}}" alt="activation qr code" class="qr_code"/>
    <p>eSIM setup manual:<br/>
    <span class="red">Please check the PDF or video in the links below for instructions on installing the eSIM profile into your device.</span></p>
  </div>
  <div class="inner manual-links">
    <a mat-stroked-button color="basic" class="manual-link" href="https://sakuramobile.jp/esim-instruction_ios" target="_blank" rel="noopener">iOS PDF</a>
    <a mat-stroked-button color="basic" class="manual-link" href="https://sakuramobile.jp/esim-instruction_android" target="_blank" rel="noopener">Android PDF</a>
    <a mat-stroked-button color="basic" class="manual-link" href="https://www.youtube.com/watch?v=yhhaoS5YfuA" target="_blank" rel="noopener">iOS video</a>
    <a mat-stroked-button color="basic" class="manual-link" href="https://www.youtube.com/watch?v=npecNSM_X7w" target="_blank" rel="noopener">Android video</a>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onClose()">Close</button>
  </mat-dialog-actions>
</div>
