import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookingComponent } from './components/pages/booking/booking.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { InstallationComponent } from './components/pages/installation/installation.component';
import { TopupComponent } from './components/pages/topup/topup.component';
import { UsageComponent } from './components/pages/usage/usage.component';

const routes: Routes = [
  { path: '', redirectTo: '/operator_auth', pathMatch: 'full' },
  { path: 'booking', component: BookingComponent },
  { path: 'installation', component: InstallationComponent },
  { path: 'topup', component: TopupComponent },
  { path: 'usage', component: UsageComponent },
  { path: 'error', component: ErrorPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
