import { Component, OnInit, Inject  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit{

  public activation_data: any;

  constructor(
    private dialogRef: MatDialogRef<QrCodeComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    console.log("QrCodeComponent::constructor dialog_data", data);
    this.activation_data = data;
  }

  ngOnInit(): void {
      
  }

  onClose() {
    console.log("QrCodeComponent::onClose");
    this.dialogRef.close('close');
  }

  public get qrUrl() {
    return `${environment.api_root}/tmp/esim_qr_${this.activation_data.access_hash}.png`;
  }
}
