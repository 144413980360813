<div class="app-maintenance-info">
  <div *ngIf="(in_maintenance && in_maintenance.in_schedule) || (planed_maintenances && planed_maintenances.length > 0)" class="maintenance_info">
    Information :
    <div *ngIf="in_maintenance && in_maintenance.in_schedule" class="in_maintenance">
      Adding Data, Extension, and Usage-check functions will be unavailable during the following period.
      <table class="schedule">
        <tbody>
          <tr>
            <td>&#8594;</td>
            <td class="term">
              {{ in_maintenance.schedule.start_at | date : "MMM-d" }}({{ in_maintenance.schedule.start_at | weekday : "s" : "u" }})
              {{ in_maintenance.schedule.start_at | date : "HH:mm" }} ~ <wbr />
              {{ in_maintenance.schedule.end_at | date : "MMM-d" }}({{ in_maintenance.schedule.end_at | weekday : "s" : "u" }})
              {{ in_maintenance.schedule.end_at | date : "HH:mm" }}
            </td>
            <td class="reason">{{ in_maintenance.schedule.reason }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="planed_maintenances.length > 0" class="plan_maintenances">
      Add data & Extension and Usage check will be unavailable during the following period.
      <table class="schedule">
        <tbody>
          <tr *ngFor="let schedule of planed_maintenances">
            <td>&#8594;</td>
            <td class="term">
              {{ schedule.start_at | date : "MMM-d" }}({{ schedule.start_at | weekday : "s" : "u" }}) {{ schedule.start_at | date : "HH:mm" }} ~ <wbr />
              {{ schedule.end_at | date : "MMM-d" }}({{ schedule.end_at | weekday : "s" : "u" }}) {{ schedule.end_at | date : "HH:mm" }}
            </td>
            <td class="reason">{{ schedule.reason }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>