<div class="app-start-using-confirmation">
  <div class="description">
    <div class="title">Notice:</div>
    <p>
      Please confirm the following and issue your eSIM QR code.
    </p>
    <mat-checkbox [(ngModel)]="c1">
      <span style="color: red;">The eSIM profile can be installed within 48 hours after it is issued.</span>
    </mat-checkbox><br/>
    <mat-checkbox [(ngModel)]="c2">
      The eSIM plan will start from the day when the eSIM is added to the device.
    </mat-checkbox><br/>
    <mat-checkbox [(ngModel)]="c3">
      Once the eSIM is <span style="font-weight: bold">DELETED/REMOVED</span> from the device, it cannot be installed again. (It is not possible to issue the eSIM again.)
    </mat-checkbox><br/>
    <mat-checkbox [(ngModel)]="c4">
      Cancelation and refund will not be possible if the eSIM is issued.
    </mat-checkbox><br/>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="warn" class="sakura button" (click)="onConfirmed()" [disabled]="!c1 || !c2 || !c3 || !c4">Confirmed and issue eSIM</button><br />
    <button mat-stroked-button color="Basic" (click)="onCancel()">Cancel</button>
  </mat-dialog-actions>
</div>
